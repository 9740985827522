.pagination-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.pagination-btn {
    background-color: white;
    border: 1px solid #da4c76;
    color: #da4c76;
    border-radius: 25px;
    height: 30px;
    aspect-ratio: 1/1;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-btn:hover {
    background-color: #da4c76;
    color: white;
}

.pagination-btn:disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
}

.pagination-btn.active {
    background-color: #da4c76;
    color: white;
}

.items-per-page-select {
    width: auto;
    max-width: 150px;
    padding: 0.25rem 0.5rem;
    border-color: #da4c76;
    color: #da4c76;
    border-radius: 4px;
}

.items-per-page-select:focus {
    border-color: #da4c76;
    box-shadow: 0 0 0 0.2rem rgba(218, 76, 118, 0.25);
}

.prev,
.next {
    border: none;
}
