.donation-overlay {
    position: fixed;
    top: 0px;
    z-index: 299;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.donation-container {
    background-color: #fff;
    width: 60vw;
    min-width: 650px;
    position: relative;
    border-radius: 10px;
    border-color: #e8e7ee;
    height: 95vh;
    min-height: 500px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
}

.donation-container .dm-header i {
    color: #fff;
    cursor: pointer;
}

.donation-container .dm-header {
    background: linear-gradient(119.19deg, #faa05b, #f57e86);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    display: flex;
    flex-direction: row;
    color: #fff;
}

.donation-container .payment-modes h5 {
    font-size: 14px;
    font-weight: bold;
}

.donation-container .payment-modes ul {
    font-size: 12px;
}

.donation-container .dm-header .title {
    text-align: center;
    flex-grow: 1;
}

.donation-container .dm-body {
    flex-grow: 1;
    display: flex;
}

.donation-container .dm-footer {
}

.donation-container .choose-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 80px;
}

.choose-container .currency-selector button {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: none;
    border: #ccc 1 px solid;
    padding: 5px 20px;
}

.choose-container .active-button {
    background: #da4c76 !important;
    border: #da4c76 0px none !important;
    color: #fff !important;
    border-radius: 100px !important;
    padding: 8px 30px;
}

.choose-container #amount-selector {
    padding-right: 10px;
}

.choose-container #amount-selector input {
    border: 1px solid #da4c76;
    box-sizing: border-box;
    border-radius: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    padding-left: 50px;
    letter-spacing: 0.04em;
    margin-right: 5px;
    position: relative;
    width: 100%;
}

.choose-container #amount-selector .badge-secondary {
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}

.choose-container #amount-selector .badge-secondary:last-child {
    margin-right: 0px;
}

.choose-container #amount-selector .custom-amount {
    position: relative;
}

.choose-container #amount-selector .custom-amount::before {
    content: "₹ ";
    color: #000000;
    z-index: 299;
    position: absolute;
    top: 10px;
    left: 20px;
    width: 20px;
    height: 20px;
}

.choose-container #amount-selector .badge-secondary::before {
    content: "₹ ";
}

.badge-secondary.selected i {
    display: inline;
}

.badge-secondary i {
    color: rgb(167, 235, 167);
    padding-left: 5px;
    display: none;
}

.choose-container #terms a {
    color: #da4c76;
}

.choose-container #amount-selector {
}

.donation-type {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;
    justify-items: center;
    align-items: center;
    padding-top: 30px;
}

.donation-type-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    flex-direction: column;
    color: #da4c76;
}

.donation-type-item div {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.donation-type-item div:first-child {
    font-size: 120px;
}

.donation-type-item div:nth-child(2) {
    font-size: 30px;
    font-weight: bold;
    padding: 20px;
}

.donation-type-item div:last-child {
    font-size: 16px;
    font-weight: normal;
    padding: 2px;
    color: #000;
}

.donation-tab {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.donation-tab .tab-selection {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.donation-tab .tab-selection div {
    flex: 1;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    background-color: #f0f0f0;
}

.donation-tab .tab-selection div:first-child {
    border-right: #bebdbd 1px solid;
}

.donation-tab .tab-selection div.active {
    background-color: #fff;
    color: #da4c76;
}

.donation-tab .tab-content {
    padding: 20px;
}

.donation-pref p span {
    font-size: 20px;
    font-weight: 600;
}

.donation-pref p {
    font-size: 16px;
    padding-top: 20px;
}

.donation-tab .choose-container {
    padding: 2px;
    max-height: 100%;
}

.donation-container .active-button {
    margin-top: 6px;
}

#customizer {
    position: fixed;
    top: 0px;
    z-index: 310;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.popup-contain {
    min-width: 300px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    max-height: 80vh;
    height: auto;
    position: relative;
}

.popup-contain .closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
}

.popup-contain .buttons {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.popup-contain .buttons button {
    padding: 2px 5px;
    font-size: 12px;
    text-transform: uppercase;
}

.popup-contain .customizer-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.popup-contain .week-active {
    border-color: #da4c76 !important;
    background-color: #da4c76;
    color: #fff;
    position: relative;
}

.popup-contain .calendar {
    display: flex;
    flex-wrap: wrap;
    max-width: 210px;
    justify-content: left;
}

.popup-contain .calendar .item {
    width: 13%;
    justify-content: center;
    text-align: center;
}

.popup-contain select {
    padding: 5px;
    background-color: #fff;
    border: 0px none;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .donation-container {
        min-width: 95vw;
        min-height: 85vh;
        max-height: 99vh;
        font-size: 80%;
    }
    .donation-container .hide-in-mobile {
        display: none !important;
    }
    .donation-container .custom-amount {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .donation-container .active-button {
        margin-top: 6px;
    }
    .donation-type-item div:first-child {
        font-size: 20vw;
    }
    .donation-type-item div:nth-child(2) {
        font-size: 6vw;
    }
    .donation-type-item div:last-child {
        font-size: 3vw;
    }
    .donation-tab .tab-selection div {
        padding: 10px;
    }
    .donation-freq-cont .form-group {
        margin-bottom: 0px;
    }
    .label-causes label {
        font-size: 11px !important;
    }
    .choose-container {
        padding: 10px 10px !important;
    }
    .donation-container .form-group {
        max-width: 95vw;
        margin-bottom: 3px;
    }
    #amount-selector div.row div.col-md-6 div,
    #amount-selector div.row div.col-md-1 {
        text-align: center;
    }
    .currency-selector {
        text-align: center;
    }
    .payment-modes ul {
        width: 100%;
    }
    .payment-modes li {
        width: 50%;
        float: left;
    }
    .custom-amount input {
        text-align: center;
    }
    #donate-money-home h2 {
        text-align: center;
        font-size: 40px;
    }
    #donate-money-home .hero {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #donate-money-home .hero p {
        text-align: center;
    }
}

@media only screen and (max-height: 600px) {
    .mobile .dm-body {
        max-height: 80vh;
        overflow-y: scroll;
    }
}
