input[type="radio"]:checked {
    color: #da4c76;
    border: 2px solid #da4c76;
    background: #da4c76;
    content: "";
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    margin-right: 5px;
    background-clip: content-box;
    border-radius: 50%;
}

/* simulate radiobutton appearance using pseudoselector */
input[type="radio"] + label:before {
    content: "";
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    margin-right: 5px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #da4c76;
    background-color: #da4c76;
    border-radius: 50%;
}

/* appearance of checked radiobutton */
input[type="radio"]:checked + label:before {
    background-color: #da4c76;
}

/* resetting default box-sizing */
*,
*:before,
*:after {
    box-sizing: border-box;
}

.smallFileUploader {
    border: 1px dashed #da4c76;
    box-sizing: border-box;
    border-radius: 4px;
    height: calc(1.5em + 0.75rem + 2px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 5px;
    cursor: pointer;
}

.smallFileUploader div {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    height: 100%;
    text-transform: uppercase;
    color: #da4c76;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.uploader_container {
    padding: 8px;
    border: 1px dashed #da4c76;
    display: flex;
    flex-direction: row;
    width: 200px;
    margin-bottom: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
}

.uploader_preview {
    padding: "10px";
}

.upload_change_text {
    padding: 10px;
    text-align: center;
}

.file-upload-section {
}

.file-upload-section-title {
    display: flex;
    flex-direction: column;
}

.file-upload-section-title .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
}

.file-upload-section-title .grey {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
}

.file-upload-section .addFile {
    box-sizing: border-box;
    border-radius: 10px;
    width: 200px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    -moz-box-shadow: inset 0 0 10px #ccc;
    -webkit-box-shadow: inset 0 0 10px #ccc;
    box-shadow: inset 0 0 10px #ccc;
}

.file-upload-section img {
    width: 30%;
    /* border-radius: 10px; */
}

.file-upload-section .videoThumbnail img {
    width: 80%;
    border-radius: 10px;
}

.file-upload-section .image img {
    width: 50%;
    max-height: 80%;
    border-radius: 10px;
}

.file-upload-section .videoThumbnail .react-thumbnail-generator {
    display: flex;
    justify-content: center;
}

.file-upload-section p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 5px;
}

.label-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 720px) and (max-width: 819px){
    .label-group{
        margin-top: 7px;
    }
}
label.grey {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.addFile .addFileTitle {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #da4c76;
    text-align: center;
    padding-top: 10px;
}

.fullscreenMode {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 95vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
    padding: 10px;
}

.fullscreenMode .quill {
    height: 80vh !important;
}

.document-name > div {
    padding-left: 0px;
    margin-left: 0px;
}
